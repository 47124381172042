import React from 'react';
import Body from './Body';

function App() {
  return (
    <div>
      {/* <Header /> */}
      <Body />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
